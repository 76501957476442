<template>
  <div :class="classes">
    <DpHeader v-if="isDpCommonTemplate" ref="header" />
    <DpHeaderTabs v-if="navigation" :contents="navigation" />

    <router-view />
  </div>
</template>

<script>
import { VIEW_TEMPLATE } from '@/constants/ViewTemplate';
import { defineComponent, computed, ref, provide } from '@vue/composition-api';

export default defineComponent({
  components: {
    DpHeader: () => import('@/components/dripPod/header/Header.vue'),
    DpHeaderTabs: () => import('@/components/dripPod/header/Tabs.vue')
  },

  setup: (props, context) => {
    const header = ref(undefined);

    const isDpCommonTemplate = computed(
      () => context.root.$route.meta.template === VIEW_TEMPLATE.DP_COMMON
    );

    const navigation = computed(() => context.root.$route.meta?.navigation);

    const setTitle = (title) => header.value?.setTitle(title);
    const setBackTo = (to) => header.value?.setBackTo(to);

    const classes = computed(() => [
      isDpCommonTemplate.value ? 'dp-content-common-template' : false,
      navigation.value ? 'dp-content-common-template--hasNavigation' : false
    ]);

    provide('setHeaderTitle', setTitle);
    provide('setHeaderBackTo', setBackTo);

    // eslint-disable-next-line no-console
    console.debug('UA :>> ', window.navigator.userAgent);

    return {
      isDpCommonTemplate,
      classes,
      navigation,
      header
    };
  }
});
</script>

<style lang="scss">
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

body {
  // Drip Podではベースを#ffffffにする
  background-color: variables.$dpWhite01;
}

body,
.l-wrap {
  min-height: 100vh;
}
</style>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-content-common-template {
  $headerHeight: 48px;

  padding-top: $headerHeight;
  min-height: 100vh;

  &.dp-content-common-template--hasNavigation {
    $navigationHeight: 54px;

    padding-top: calc($headerHeight + $navigationHeight);
  }
}
</style>
