var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _vm.isDpCommonTemplate ? _c("DpHeader", { ref: "header" }) : _vm._e(),
      _vm.navigation
        ? _c("DpHeaderTabs", { attrs: { contents: _vm.navigation } })
        : _vm._e(),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }